var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex justify-content-center",
      staticStyle: { "min-width": "60px" },
    },
    [
      _vm.item
        ? _c(
            "b-dropdown",
            {
              class: _vm.lengthRecords <= 8 ? "position-absolute" : "",
              attrs: {
                id: "dropdown-offer-action",
                size: "sm",
                text: _vm.FormMSG(1, "Menus"),
                variant: "light",
                split: "",
                dropleft: "",
              },
            },
            [
              _c("share-information", {
                key: _vm.item.id,
                staticClass: "mr-2",
                attrs: {
                  "btn-label": _vm.FormMSG(2, "Share Offer"),
                  "title-modal": _vm.FormMSG(3, "Share the offer to :"),
                  offer: _vm.item,
                  tag: "b-dropdown-item",
                  "name-modal": `modal-share-offer${_vm.item.id}`,
                  icon: "fa fa-share-alt",
                  "variant-btn": "info",
                },
                on: { "share-information:sended": _vm.shareOffer },
              }),
              _c(
                "b-dropdown-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.downloadOfferInPdf(_vm.item)
                    },
                  },
                },
                [
                  _c("b-icon", {
                    staticStyle: { color: "red" },
                    attrs: { icon: "file-earmark-pdf-fill" },
                  }),
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.FormMSG(10, "Download in PDF")) +
                      "\n\t\t"
                  ),
                ],
                1
              ),
              _c(
                "b-dropdown-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.downloadOfferInDoc(_vm.item)
                    },
                  },
                },
                [
                  _c("b-icon", {
                    staticStyle: { color: "#317ac1" },
                    attrs: { icon: "file-earmark-word-fill" },
                  }),
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.FormMSG(11, "Download in Word")) +
                      "\n\t\t"
                  ),
                ],
                1
              ),
              _c(
                "b-dropdown-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.downloadOfferInXls(_vm.item)
                    },
                  },
                },
                [
                  _c("b-icon", {
                    staticStyle: { color: "green" },
                    attrs: { icon: "file-excel-fill" },
                  }),
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.FormMSG(13, "Download in Excel")) +
                      "\n\t\t"
                  ),
                ],
                1
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: { disabled: _vm.item.sendStatus === 5 },
                  on: {
                    click: function ($event) {
                      return _vm.handleClickAccepted(_vm.item)
                    },
                  },
                },
                [
                  _c("b-icon", {
                    staticStyle: { color: "blue" },
                    attrs: { icon: "check-circle" },
                  }),
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.FormMSG(5, "Accept offer")) +
                      "\n\t\t"
                  ),
                ],
                1
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: {
                    disabled: !(
                      _vm.item.sendStatus === 5 &&
                      _vm.item.isTaskCreated === false
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleCreateTask(_vm.item)
                    },
                  },
                },
                [
                  _c("b-icon", {
                    staticStyle: { color: "#a64b2a" },
                    attrs: { icon: "list-task" },
                  }),
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.FormMSG(6, "Create tasks")) +
                      "\n\t\t"
                  ),
                ],
                1
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: { disabled: _vm.item.sendStatus === 0 },
                  on: {
                    click: function ($event) {
                      return _vm.handleRejectOffer(_vm.item)
                    },
                  },
                },
                [
                  _c("b-icon", {
                    staticStyle: { color: "red" },
                    attrs: { icon: "x-circle" },
                  }),
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.FormMSG(7, "Reject offer")) +
                      "\n\t\t"
                  ),
                ],
                1
              ),
              _c(
                "b-dropdown-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleCopyOffer(_vm.item)
                    },
                  },
                },
                [
                  _c("b-icon", {
                    staticStyle: { color: "green" },
                    attrs: { icon: "subtract" },
                  }),
                  _vm._v(
                    "\n\t\t\t" + _vm._s(_vm.FormMSG(8, "Copy offer")) + "\n\t\t"
                  ),
                ],
                1
              ),
              _c(
                "b-dropdown-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleVariantOffer(_vm.item)
                    },
                  },
                },
                [
                  _c("b-icon", {
                    staticStyle: { color: "green" },
                    attrs: { icon: "stickies" },
                  }),
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.FormMSG(9, "Create offer variant")) +
                      "\n\t\t"
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }