<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-col cols="12" xl="12">
			<transition name="slide">
				<div>
					<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
						{{ rendTitle }}
					</h1>
					<b-card no-body>
						<b-card-header v-if="showBtnNewTask">
							<b-row>
								<b-col>
									<div class="float-right">
										<b-button
											v-if="!isMyWorkSpace && !isOffer"
											:block="!$screen.md"
											@click="newTask"
											variant="success"
											size="md"
											class="btn btn-primary btn-md float-right"
										>
											{{ this.FormMSG(4, 'New Task') }}
										</b-button>
										<b-button
											v-if="!isMyWorkSpace && isOffer"
											:block="!$screen.md"
											variant="success"
											size="md"
											class="btn btn-primary btn-md float-right"
											@click="newOffer"
										>
											{{ FormMSG(157, 'New Offer') }}
										</b-button>
									</div>
								</b-col>
							</b-row>
						</b-card-header>
						<b-card-body>
							<div style="position: relative">
								<custom-loading-planning :loading="screenLoading" :label="FormMSG(105, 'Preparing the task list')" />
								<b-row class="mb-3">
									<b-col md="4" lg="4" class="my-1" v-if="$screen.width >= 992">
										<b-form-group
											:label="FormMSG(2, 'Filter')"
											label-cols="3"
											label-align-sm="left"
											label-size="sm"
											label-for="filterInput"
											class="mb-0"
										>
											<b-input-group size="sm">
												<b-form-input
													v-model="filter"
													type="search"
													id="filterInput"
													:placeholder="this.FormMSG(3, 'Type to Search')"
												></b-form-input>
												<b-input-group-append>
													<b-button :disabled="!filter" @click="filter = ''">
														<i class="fa fa-times" aria-hidden="true"></i>
													</b-button>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="8">
										<fieldset class="scheduler-border my-0 py-0">
											<legend class="scheduler-border">{{ FormMSG(20, 'Advanced search') }}</legend>
											<filter-planning
												:field-filter="'task'"
												:filter-for-task="true"
												:to-me="isMyWorkSpace"
												:is-not-planning="!isMyWorkSpace"
												:only-parent="chckOnlyParent"
												init-active-view="day"
												@filters:selected="handleFiltersSelected"
												@new-active-view="handleNewActiveView"
												@today-event="$refs.calTasks.switchView('day', new Date())"
												@specific-date:selected="handleSpecificDateSelected"
												@only-parent:change="handleOnlyParentChange"
											/>
										</fieldset>
									</b-col>
								</b-row>
								<div class="alert alert-warning" role="alert" v-if="projectFormatEmpty && !isMyWorkSpace && isOffer">
									<div class="d-flex align-items-center" style="color: rgba(0, 0, 0, 0.6)">
										<div class="pr-3" style="font-size: 1.35rem"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></div>
										<div class="mt-0">
											{{
												FormMSG(
													21,
													'Before adding a new offer, you need to set up in your project the number format for offer, order and invoice.'
												)
											}}
										</div>
									</div>
								</div>
								<b-row id="container-calTasks">
									<b-col>
										<vue-cal
											ref="calTasks"
											:locale="lang"
											:hide-body="true"
											:hide-weekends="true"
											:selected-date="selectedDate"
											hide-view-selector
											:hide-week-days="[1, 2, 3, 4, 5, 6, 7]"
											:active-view.sync="activeView"
											@view-change="handleViewChange"
										/>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<b-overlay :show.sync="isBusyTable" rounded="sm">
											<CardListBuilder v-if="$screen.width < 992" :items="tasks" :fields="taskMobileFields">
												<template slot="actions" slot-scope="data">
													<div v-if="!isOffer">
														<div v-if="data.item.percentCompleted !== 100" class="d-flex flex-row">
															<div class="text-center completed-label pull-right">
																<b-form-input
																	v-model="data.item.percentCompleted"
																	type="number"
																	step="1"
																	min="0"
																	max="100"
																	style="height: 22px; width: 50px"
																	@keyup.enter="savePercent(data)"
																/>
															</div>
															<div id="completed-percent" class="px-1 completed-edit" @click="savePercent(data)">
																<i class="fa fa-check" aria-hidden="true"></i>
															</div>
															<b-tooltip show target="completed-percent" placement="bottom">{{ FormMSG(67, 'Save') }}</b-tooltip>
														</div>
														<div v-else>{{ data.item.percentCompleted }} %</div>
													</div>
													<div v-if="(data.item.addressId > 0 || data.item.locationId > 0) && !isOffer">
														<b-button variant="warning" size="sm" @click="handleClickLocalisation(data.item)">
															<span>
																<i class="fa fa-map-marker" aria-hidden="true"></i>
															</span>
														</b-button>
													</div>
													<div v-if="isMyWorkSpace && !isOffer" class="d-inline-flex">
														<b-button
															size="sm"
															variant="outline-warning"
															style="line-height: 16px"
															@click="play(data)"
															v-if="data.item.start === '0001-01-01T00:00:00Z'"
														>
															<i class="fa fa-play" aria-hidden="true"></i>
														</b-button>
														<b-button
															size="sm"
															variant="outline-danger"
															style="line-height: 16px"
															@click="stop(data)"
															v-if="data.item.end === '0001-01-01T00:00:00Z'"
														>
															<i class="fa fa-stop" aria-hidden="true"></i>
														</b-button>
													</div>
													<b-button size="sm" style="line-height: 16px" @click="doComment(data.item)" v-if="!isOffer">
														<b-icon icon="chat-left-text" />
													</b-button>
													<b-button variant="primary" size="sm" @click="editTask(data.item)">
														<span v-if="isMyWorkSpace">
															<i class="icon-eye"></i>
														</span>
														<span v-else>
															<i class="icon-pencil"></i>
														</span>
													</b-button>
													<b-button
														variant="danger"
														size="sm"
														@click="deleteTask(data.item.taskPlanningId, data.item.id)"
														v-if="!isMyWorkSpace"
													>
														<span>
															<i class="icon-trash"></i>
														</span>
													</b-button>
												</template>
											</CardListBuilder>
											<b-table
												v-if="$screen.width >= 992"
												:selected-variant="selectedColor"
												:hover="hover"
												selectable
												:select-mode="selectMode"
												responsive="sm"
												ref="taskTable"
												sticky-header="700px"
												:items="tasks"
												style="text-align: left"
												:fields="taskFields"
												:current-page="currentPage"
												:filter="filter"
												:per-page="perPage"
												bordered
												small
												:head-variant="hv"
												:empty-text="!isOffer ? FormMSG(55, 'No task found') : FormMSG(68, 'No offer found')"
												show-empty
												tbody-tr-class="p-0"
											>
												<template v-slot:cell(location)="data">
													<div v-if="data.item.addressId > 0 || data.item.locationId > 0">
														<div
															:id="`tooltip-location-${data.item.taskPlanningId}`"
															class="text-center map"
															@click="handleClickLocalisation(data.item)"
														>
															<i class="fa fa-map-marker" aria-hidden="true"></i>
														</div>
														<b-tooltip :target="`tooltip-location-${data.item.taskPlanningId}`" placement="righttop">
															<span v-if="data.item.locationId === 0">
																{{ getAddressFormater(data.item.client.address) }}
															</span>
															<span v-else>
																{{ getAddressFormater(data.item.location.setAddress) }}
															</span>
														</b-tooltip>
													</div>
												</template>
												<template v-slot:cell(completed)="data">
													<div class="text-center" style="width: 125px">
														<div v-if="data.item.percentCompleted !== 100" class="d-flex flex-row">
															<div class="col-sm-8 text-center completed-label">
																<div :ref="`completed-label-${data.item.taskPlanningId}`" style="display: block">
																	{{ data.item.percentCompleted }} %
																</div>
																<div
																	:ref="`completed-div-input-${data.item.taskPlanningId}`"
																	style="display: none"
																	@keydown.esc="cancelSave(data)"
																>
																	<b-form-input
																		v-model="data.item.percentCompleted"
																		:ref="`completed-input-${data.item.taskPlanningId}`"
																		:id="`tooltip-input-${data.item.taskPlanningId}`"
																		type="number"
																		step="1"
																		min="0"
																		max="100"
																		style="height: 22px; width: 80px"
																		@keyup.enter="savePercent(data)"
																	/>
																</div>
																<b-tooltip :target="`tooltip-input-${data.item.taskPlanningId}`" placement="left">
																	{{ FormMSG(60, 'Type `ESC` to discard the change') }}
																</b-tooltip>
															</div>
															<div
																:ref="`completed-btn-edit-${data.item.taskPlanningId}`"
																class="col-sm-4 px-1 completed-edit"
																@click="editPercent(data.item.taskPlanningId, data.item.percentCompleted)"
																style="display: block"
															>
																<i class="fa fa-pencil" aria-hidden="true"></i>
															</div>
															<div
																:ref="`completed-btn-save-${data.item.taskPlanningId}`"
																style="display: none"
																class="col-sm-4 px-1 completed-edit"
																@click="savePercent(data)"
															>
																<i class="fa fa-check" aria-hidden="true"></i>
															</div>
														</div>
														<div v-else>{{ data.item.percentCompleted }} %</div>
													</div>
												</template>
												<template v-slot:cell(play)="data">
													<b-button
														size="sm"
														variant="outline-warning"
														style="line-height: 16px"
														@click="play(data)"
														v-if="data.item.start === '0001-01-01T00:00:00Z'"
													>
														<i class="fa fa-play" aria-hidden="true"></i>
													</b-button>
												</template>
												<template v-slot:cell(stop)="data">
													<b-button
														size="sm"
														variant="outline-danger"
														style="line-height: 16px"
														@click="stop(data)"
														v-if="data.item.end === '0001-01-01T00:00:00Z'"
													>
														<i class="fa fa-stop" aria-hidden="true"></i>
													</b-button>
												</template>
												<template v-slot:cell(edit)="data">
													<div v-if="isOffer">
														<b-button
															variant="primary"
															size="sm"
															@click="editOffer(data.item)"
															class="mx-2 btn bg-transparent border-0"
														>
															<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
														</b-button>
													</div>
													<div v-else>
														<b-button
															variant="primary"
															size="sm"
															@click="editTask(data.item)"
															style="line-height: 16px"
															class="btn bg-transparent border-0"
														>
															<span v-if="isMyWorkSpace">
																<component :is="getLucideIcon(ICONS.EYE.name)" color="#225CBD" :size="20" />
															</span>
															<span v-else>
																<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
															</span>
														</b-button>
													</div>
												</template>
												<template v-slot:cell(totalBudget)="data">
													{{ displayCurrency(data.item.totalBudget) }}
												</template>
												<template v-slot:cell(totalBudgetTtc)="data">
													{{ displayCurrency(data.item.totalBudgetTtc) }}
												</template>
												<template v-slot:cell(contextMenu)="data">
													<action-offer-context-menu
														:record="data.item"
														:records="tasks"
														@action-menu-share-offer:sended="shareOffer"
														@offer-action:refresh="reloadData"
													/>
												</template>
												<template v-slot:cell(chat)="data">
													<b-button
														size="sm"
														style="line-height: 16px; color: #225cbd"
														@click="doComment(data.item)"
														class="btn bg-transparent border-0"
													>
														<b-icon icon="chat-left-text" />
													</b-button>
												</template>
												<template v-slot:cell(delete)="data">
													<div class="d-flex justify-content-center">
														<b-button
															variant="danger"
															size="sm"
															style="line-height: 16px"
															@click="deleteTask(data.item.taskPlanningId, data.item.id)"
															class="btn bg-transparent border-0"
														>
															<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
														</b-button>
													</div>
												</template>
											</b-table>
										</b-overlay>
									</b-col>
								</b-row>
							</div>
						</b-card-body>
					</b-card>
					<comment-modal
						v-model="isOpenCommentModal"
						:key-value="{ xid: currentXid, imageParentId: currentRootId }"
						:title="currentTaskName"
						@comment-modal:hidden="handleCloseCommentModal"
					/>
				</div>
			</transition>
		</b-col>
	</errorContainer>
</template>

<script>
import { getTasks, addTaskResourceUser, delTask, updatedTask, isProjectLabelFormatEmpty } from '@/cruds/task.crud';
import { isNil, capitalize } from '~utils';
import languageMessages from '@/mixins/languageMessages';
import moment from 'moment';
import { store } from '@/store';
import FilterPlanning from '@/components/Plannings/Filter/FilterPlanning';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import 'vue-cal/dist/i18n/fr.js';
import 'vue-cal/dist/i18n/it.js';
import 'vue-cal/dist/i18n/de.js';
import 'vue-cal/dist/i18n/nl.js';
import 'vue-cal/dist/i18n/pt-br.js';
import 'vue-cal/dist/i18n/sk.js';
import CustomLoadingPlanning from '@/components/Plannings/Filter/CustomLoadingPlanning';
import GlobalMixin from '@/mixins/global.mixin';
import TaskMapLocation from '@/components/Task/TaskMapModal';
import { addressFormatter } from '@/shared/fomaters';
import OffersMixin from '@/components/Offers/offers.mixin';
import { rendCurrency } from '~helpers';
import ActionOfferContextMenu from '@/components/Offers/ActionContextMenu';
import CommentModal from '@/components/Chat/CommentModal';

export default {
	name: 'TaskList',
	mixins: [languageMessages, GlobalMixin, OffersMixin],
	components: {
		FilterPlanning,
		VueCal,
		CustomLoadingPlanning,
		TaskMapLocation,
		ActionOfferContextMenu,
		CommentModal
	},
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	data() {
		const date = new Date();
		return {
			hv: 'dark',
			erreur: {},
			warning: '',
			tasks: [],
			tasksOriginal: [],
			filter: '',
			selectMode: 'single',
			selectedColor: 'primary',
			currentPage: 1,
			perPage: 0,
			task: {
				name: 'Default task',
				description: 'Default description task',
				taskTypeId: 0,
				clientId: 0,
				duration: '0001-01-01T01:00:00Z',
				status: 1,
				priority: 0,
				desiredStart: null,
				dayDuration: 1,
				putInPlanning: true
				// images: [],
			},
			filterBy: '()',
			activeView: 'day',
			filterStartDate: new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1, 0, 0, 0).format('YYYY-MM-DD') + 'T00:00:00Z',
			filterEndDate: new Date().format('YYYY-MM-DD') + 'T23:59:00Z',
			screenLoading: false,
			selectedDate: new Date(),
			isMyWorkSpace: false,
			oldValue: 0,
			isOpenMapLocation: false,
			currentAddress: {},
			literalAddress: '',
			chckOnlyParent: false,
			cookiesTasks: {
				filterStartDate: new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1, 0, 0, 0).format('YYYY-MM-DD') + 'T00:00:00Z',
				filterEndDate: new Date().format('YYYY-MM-DD') + 'T23:59:00Z',
				filterBy: '()',
				activeView: 'day',
				selectedDate: new Date(),
				chckOnlyParent: false
			},
			cookieName: '',
			projectFormatEmpty: false,
			isBusyTable: false,
			isOpenCommentModal: false,
			currentXid: '',
			currentRootId: 0,
			currentTaskName: ''
		};
	},
	computed: {
		showBtnNewTask() {
			if (!this.isMyWorkSpace) {
				return true;
			} else if (this.isOffer) {
				return true;
			}

			return false;
		},
		activeViewText() {
			if (this.activeView === 'year') {
				return this.FormMSG(91, 'Year');
			}
			if (this.activeView === 'month') {
				return this.FormMSG(92, 'Month');
			}
			if (this.activeView === 'week') {
				return this.FormMSG(93, 'Week');
			}
			if (this.activeView === 'day') {
				return this.FormMSG(94, 'Day');
			}
		},
		statuses() {
			return this.FormMenu(1122);
		},
		priorities() {
			return this.FormMenu(1123);
		},
		/**
		 * @return {String}
		 */
		rendTitle() {
			if (this.isOffer) {
				return this.FormMSG(156, 'Offer list');
			} else if (this.isMyWorkSpace) {
				return this.FormMSG(65, 'My Tasks');
			} else {
				return this.FormMSG(1, 'Tasks');
			}
		},
		// Loading
		baseFields() {
			const fullFileds = [
				{
					key: 'taskPlanningId',
					label: this.FormMSG(5, '#ID'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'orderNumber',
					label: this.FormMSG(23, 'N° Order'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'name',
					label: this.FormMSG(6, 'Name'),
					formatter: (value) => {
						return capitalize(value);
					},
					sortable: true,
					sortByFormatted: true,
					filterByFormatted: true
				},
				{
					key: 'taskParentName',
					label: this.FormMSG(43, 'Main task'),
					formatter: (value) => {
						return capitalize(value);
					},
					sortable: true,
					sortByFormatted: true,
					filterByFormatted: true
				},
				{
					key: 'type.name',
					label: this.FormMSG(7, 'Type'),
					sortable: true
				},
				{
					key: 'client.name',
					label: this.FormMSG(10, 'Client'),
					sortable: true
				},
				{
					key: 'location',
					label: this.FormMSG(63, 'Location'),
					sortable: false,
					formatter: (v, z, root) => {
						if (this.$screen.width < 992) {
							if (root.addressId > 0 || root.locationId > 0) {
								if (root.locationId === 0) {
									return this.getAddressFormater(root.client.address);
								} else {
									return this.getAddressFormater(root.location.setAddress);
								}
							}

							return '';
						}
					},
					class: 'text-center'
				},
				{
					key: 'fullName',
					label: this.FormMSG(11, 'Created By'),
					formatter: (v, z, root) => {
						return root.owner.firstName + ' ' + root.owner.name.toUpperCase();
					},
					sortable: true
				},
				{
					key: 'desiredStart',
					label: this.FormMSG(18, 'Desired Start'),
					formatter: (value) => {
						return value !== '0001-01-01T00:00:00Z' ? new moment(value).format('DD/MM/YYYY HH:mm') : '-';
					},
					sortByFormatted: true,
					filterByFormatted: true,
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'dayDuration',
					label: this.FormMSG(19, 'Nb Days'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'priority',
					label: this.FormMSG(14, 'Priority'),
					formatter: (value) => {
						const findIndex = this.priorities.findIndex((s) => s.value === value);
						return this.priorities[findIndex].text;
					},
					sortable: true,
					sortByFormatted: true,
					filterByFormatted: true,
					class: 'text-center'
				},
				{
					key: 'status',
					label: this.FormMSG(13, 'Status'),
					formatter: (value, key, item) => {
						if (!this.isOffer) {
							const findIndex = this.statuses.findIndex((s) => s.value === value);
							return this.statuses[findIndex].text;
						} else {
							return value;
						}
					},
					sortable: true,
					sortByFormatted: true,
					filterByFormatted: true,
					class: 'text-center'
				},
				{
					key: 'start',
					label: this.FormMSG(61, 'Start at'),
					formatter: (value) => {
						return value !== '0001-01-01T00:00:00Z' ? new moment(value).format('DD/MM/YYYY HH:mm') : '-';
					},
					sortable: true,
					sortByFormatted: true,
					filterByFormatted: true,
					class: 'text-center'
				},
				{
					key: 'end',
					label: this.FormMSG(62, 'End at'),
					formatter: (value) => {
						return value !== '0001-01-01T00:00:00Z' ? new moment(value).format('DD/MM/YYYY HH:mm') : '-';
					},
					sortable: true,
					sortByFormatted: true,
					filterByFormatted: true,
					class: 'text-center'
				},
				{
					key: 'completed',
					label: this.FormMSG(57, 'Completed'),
					sortable: false,
					formatter: (v, z, root) => {
						if (this.$screen.width < 992) {
							return root.percentCompleted + '%';
						}
					},
					class: 'text-center completed-col'
				},
				{
					key: 'chat',
					label: this.FormMSG(69, 'Comment'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'edit',
					label: this.FormMSG(15, 'Edit'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				},
				{
					key: 'delete',
					label: this.FormMSG(16, 'Remove'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
			const myFields = [
				{
					key: 'name',
					label: this.FormMSG(6, 'Name'),
					formatter: (value) => {
						return capitalize(value);
					},
					sortable: true
				},
				{
					key: 'taskParentName',
					label: this.FormMSG(43, 'Main task'),
					formatter: (value) => {
						return capitalize(value);
					},
					sortable: true
				},
				{
					key: 'client.name',
					label: this.FormMSG(10, 'Client'),
					sortable: true
				},
				{
					key: 'location',
					label: this.FormMSG(63, 'Location'),
					sortable: false,
					formatter: (v, z, root) => {
						if (this.$screen.width < 992) {
							if (root.addressId > 0 || root.locationId > 0) {
								if (root.locationId === 0) {
									return this.getAddressFormater(root.client.address);
								} else {
									return this.getAddressFormater(root.location.setAddress);
								}
							}

							return '';
						}
					},
					class: 'text-center'
				},
				{
					key: 'desiredStart',
					label: this.FormMSG(18, 'Desired Start'),
					formatter: (value) => {
						return value !== '0001-01-01T00:00:00Z' ? new moment(value).format('DD/MM/YYYY HH:mm') : '-';
					},
					sortable: true,
					sortByFormatted: true,
					filterByFormatted: true,
					class: 'text-center'
				},
				{
					key: 'dayDuration',
					label: this.FormMSG(19, 'Nb Days'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'start',
					label: this.FormMSG(61, 'Start at'),
					formatter: (value) => {
						return value !== '0001-01-01T00:00:00Z' ? new moment(value).format('DD/MM/YYYY HH:mm') : '-';
					},
					sortable: true,
					sortByFormatted: true,
					filterByFormatted: true,
					class: 'text-center'
				},
				{
					key: 'end',
					label: this.FormMSG(62, 'End at'),
					formatter: (value) => {
						return value !== '0001-01-01T00:00:00Z' ? new moment(value).format('DD/MM/YYYY HH:mm') : '-';
					},
					sortable: true,
					sortByFormatted: true,
					filterByFormatted: true,
					class: 'text-center'
				},
				{
					key: 'priority',
					label: this.FormMSG(14, 'Priority'),
					formatter: (value) => {
						const findIndex = this.priorities.findIndex((s) => s.value === value);
						return this.priorities[findIndex].text;
					},
					sortable: true,
					sortByFormatted: true,
					filterByFormatted: true,
					class: 'text-center'
				},
				{
					key: 'status',
					label: this.FormMSG(13, 'Status'),
					formatter: (value) => {
						const findIndex = this.statuses.findIndex((s) => s.value === value);
						return this.statuses[findIndex].text;
					},
					sortable: true,
					sortByFormatted: true,
					filterByFormatted: true,
					class: 'text-center'
				},
				{
					key: 'completed',
					label: this.FormMSG(57, 'Completed'),
					sortable: false,
					formatter: (v, z, root) => {
						if (this.$screen.width < 992) {
							return root.percentCompleted + '%';
						}
					},
					class: 'text-center completed-col'
				},
				{
					key: 'play',
					label: this.FormMSG(58, 'Start'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'stop',
					label: this.FormMSG(59, 'Stop'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'chat',
					label: this.FormMSG(69, 'Chat'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'edit',
					label: this.FormMSG(56, 'View'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
			if (!this.isOffer) {
				return this.isMyWorkSpace ? myFields : fullFileds;
			} else {
				return this.offerFields;
			}
		},
		taskFields() {
			return this.baseFields;
		},
		taskMobileFields() {
			const keys = ['play', 'stop', 'edit', 'delete', 'contextMenu', 'chat'];
			return this.baseFields.filter((t) => !keys.includes(t.key));
		}
	},
	mounted() {
		this.$nextTick(async () => {
			await this.getCookiesTasks();
			const currentPath = this.$router.currentRoute.path;
			this.isMyWorkSpace = new RegExp('(mytasks)', 'g').test(currentPath);
			if (this.isOffer) {
				this.chckOnlyParent = true;
			}
			await this.reloadData();
			await this.isProjectFormatEmpty();
		});
	},
	methods: {
		async shareOffer(payload) {
			const findIndex = this.tasks.findIndex((t) => parseInt(t.id) === parseInt(payload.id));
			if (this.tasks[findIndex].sendStatus !== 5) {
				this.tasks[findIndex].sendStatus = 1;
			}
		},
		displayCurrency(value) {
			return rendCurrency(value);
		},
		async handleClickLocalisation(event) {
			if (event.locationId === 0) {
				await this.displayMap(event.client.address);
			} else {
				await this.displayMap(event.location.setAddress);
			}
		},
		getAddressFormater(address) {
			return addressFormatter(address);
		},
		getStatus(value) {
			const findIndex = this.statuses.findIndex((s) => s.value === value);
			return this.statuses[findIndex].text;
		},
		getCookiesTasks() {
			switch (this.$route.name) {
				case 'taskList':
					this.cookieName = 'ck_plannings_component_for_tasks';
					break;
				case 'myTasks':
					this.cookieName = 'ck_plannings_component_for_myTasks';
					break;
				case 'offers':
					this.cookieName = 'ck_plannings_component_for_offers';
					break;
				case 'administrationTasks':
					this.cookieName = 'ck_plannings_component_for_administration_tasks';
					break;
				case 'administrationOffers':
					this.cookieName = 'ck_plannings_component_for_administration_offers';
					break;
			}
			if (this.$cookies.isKey(this.cookieName)) {
				this.cookiesTasks = this.$cookies.get(this.cookieName);
				this.filterStartDate = new Date(this.cookiesTasks.filterStartDate).toISOString();
				this.filterEndDate = new Date(this.cookiesTasks.filterEndDate).toISOString();
				this.filterBy = this.cookiesTasks.filterBy;
				this.activeView = this.cookiesTasks.activeView;
				this.selectedDate = new Date(this.cookiesTasks.selectedDate);
				this.chckOnlyParent = this.cookiesTasks.chckOnlyParent;
			} else {
				this.$cookies.set(this.cookieName, this.cookiesTasks, '2d');
			}
		},
		setCookiesTasks() {
			this.cookiesTasks.filterStartDate = this.filterStartDate;
			this.cookiesTasks.filterEndDate = this.filterEndDate;
			this.cookiesTasks.filterBy = this.filterBy;
			this.cookiesTasks.activeView = this.activeView;
			this.cookiesTasks.selectedDate = this.filterStartDate;
			this.cookiesTasks.chckOnlyParent = this.chckOnlyParent;
			this.$cookies.set(this.cookieName, this.cookiesTasks, '2d');
		},
		async handleOnlyParentChange(payload) {
			this.chckOnlyParent = payload === 'true';
			this.setCookiesTasks();
			await this.reloadData();
		},
		async handleSpecificDateSelected(payload) {
			this.filterStartDate = payload.startDate;
			this.filterEndDate = payload.endDate;

			this.selectedDate = new Date(payload.startDate);
			this.setCookiesTasks();
			await this.reloadData();
		},
		handleNewActiveView(payload) {
			this.activeView = payload;
			this.setCookiesTasks();
		},
		async handleViewChange(payload) {
			this.filterStartDate = moment(payload.startDate).utc();
			this.filterEndDate = moment(payload.endDate).utc();

			this.setCookiesTasks();
			await this.reloadData();
		},
		handleFiltersSelected({ payload, condition }) {
			this.filterBy = condition;
			this.setCookiesTasks();
			this.getTasksWithFilter();
		},
		getTasksWithFilter() {
			if (this.filterBy !== '()') {
				this.tasks = [];
				for (let i = 0; i < this.tasksOriginal.length; i++) {
					const task = this.tasksOriginal[i];
					if (eval(this.filterBy)) {
						this.tasks.push(task);
					}
				}
			} else {
				this.tasks = this.tasksOriginal;
			}
		},
		async newTask() {
			// add dynamicaly task
			// TODO update dueDate with duration value
			const today = new Date();
			let tomorrow = new Date(today);
			tomorrow.setDate(tomorrow.getDate() + 1);
			tomorrow.setHours(0, 0, 0);
			this.task.desiredStart = tomorrow;
			this.task.name = this.FormMSG(41, 'Default task');
			this.task.description = this.FormMSG(42, 'Default description task');
			await updatedTask(0, this.task).then(async (record) => {
				const { taskPlanningId, id } = record;
				if (this.$route.name === 'administrationTasks') {
					await addTaskResourceUser(taskPlanningId, store.state.myProfile.id);
					store.state.isAdministrationTasks = true;
				}
				await this.$router.replace('/tasks/' + taskPlanningId);
			});
		},
		editTask(item) {
			// store task
			store.state.task = item;
			// store root
			store.state.taskRoot = item.id;
			if (this.$route.name === 'administrationTasks') {
				store.state.isAdministrationTasks = true;
			} else {
				store.state.isAdministrationTasks = false;
			}
			const link = this.isMyWorkSpace ? 'mytasks' : 'tasks';
			this.$router.replace('/' + link + '/' + parseInt(item.taskPlanningId, 10));
		},
		async deleteTask(taskPlanningId, id) {
			const del = async () => {
				await delTask(taskPlanningId, id).then((r) => {
					if (r) {
						this.tasks.splice(
							this.tasks.findIndex((task) => task.taskPlanningId === taskPlanningId),
							1
						);

						this.createToastForMobile(this.FormMSG(54, 'Success'), this.FormMSG(17, 'Task removed successfully'), '', 'success');
					}
				});
			};
			this.confirmModal(del, this.FormMSG(95, 'Are you sure ?'));
		},
		async reloadData() {
			//console.log(this.filterStartDate)
			this.isBusyTable = true;
			const taskParentId = this.chckOnlyParent ? 0 : -1;
			const loadMainTask = this.chckOnlyParent === true;
			//const checkParent = this.isOffer ? 0 : -1
			await getTasks(taskParentId, {
				startDate: this.filterStartDate,
				endDate: this.filterEndDate,
				ofMine: this.isMyWorkSpace,
				ofToday: false,
				orderBy: true,
				loadItems: false,
				loadSubTasks: false,
				loadTeams: true,
				loadUsers: true,
				loadTreeView: false,
				loadOffer: this.isOffer,
				loadDepartment: this.$route.name === 'administrationTasks' ? store.state.myProfile.department : 0,
				loadTaskByOwner: this.$route.name === 'administrationOffers' ? parseInt(store.state.myProfile.id) : 0,
				loadMainTask
			})
				.then((records) => {
					this.tasks = records;
					this.tasksOriginal = records;
					this.getTasksWithFilter();
				})
				.finally(() => {
					this.isBusyTable = false;
				});
		},
		editPercent(id, oldValue) {
			this.oldValue = oldValue;
			this.$refs[`completed-label-${id}`].style.display = 'none';
			this.$refs[`completed-div-input-${id}`].style.display = 'block';
			this.$refs[`completed-input-${id}`].focus();
			this.$refs[`completed-btn-edit-${id}`].style.display = 'none';
			this.$refs[`completed-btn-save-${id}`].style.display = 'block';
		},
		async savePercent(data) {
			await updatedTask(data.item.id, {
				percentCompleted: parseInt(data.item.percentCompleted, 10),
				taskPlanningId: data.item.taskPlanningId
			}).then(async (r) => {
				if (parseInt(data.item.percentCompleted, 10) === 100) {
					await updatedTask(data.item.id, {
						status: 3,
						taskPlanningId: data.item.taskPlanningId
					}).then(async (r) => {
						// await this.reloadData();
					});
				}
				if (this.$screen.width >= 992) {
					this.toggleDiv(data.item.taskPlanningId);
					this.tasks[data.index].percentCompleted = parseInt(data.item.percentCompleted, 10);
				} else {
					this.createToastForMobile(this.FormMSG(54, 'Success'), this.FormMSG(66, 'Task completed percent updated successfully'), '', 'success');
				}
			});
		},
		cancelSave(data) {
			// data.item.percentCompleted = oldValue;
			this.tasks[data.index].percentCompleted = this.oldValue;
			this.toggleDiv(data.item.taskPlanningId);
		},
		toggleDiv(id) {
			this.$refs[`completed-label-${id}`].style.display = 'block';
			this.$refs[`completed-div-input-${id}`].style.display = 'none';
			this.$refs[`completed-btn-edit-${id}`].style.display = 'block';
			this.$refs[`completed-btn-save-${id}`].style.display = 'none';
		},
		async play(data) {
			const start = new Date();
			await updatedTask(data.item.id, {
				start,
				status: 2,
				taskPlanningId: data.item.taskPlanningId
			}).then(async (r) => {
				await this.reloadData();
			});
		},
		async stop(data) {
			const end = new Date();
			await updatedTask(data.item.id, {
				end,
				taskPlanningId: data.item.taskPlanningId
			}).then(async (r) => {
				if (this.$screen.width < 992) {
					await this.reloadData();
				} else {
					this.tasks[data.index].end = end;
				}
			});
		},
		async getAddress(item) {
			let address = null;
			if (item.locationId > 0) {
				const { location } = item;
				address = location.setAddress;
			} else if (item.addressId > 0) {
				address = item.address;
			}

			return address;
		},
		getLiteralAddress(item) {
			item.then((record) => {
				this.literalAddress = addressFormatter(record);
			});

			return this.literalAddress;
		},
		async isProjectFormatEmpty() {
			this.projectFormatEmpty = await isProjectLabelFormatEmpty();
		},
		doComment(item) {
			this.currentXid = item.rootXid === '' ? item.xid : item.rootXid;
			this.currentRootId = parseInt(item.rootId, 10) === 0 ? parseInt(item.id, 10) : parseInt(item.rootId, 10);
			// console.log({currentXid: this.currentXid, currentRootId: this.currentRootId})
			this.currentTaskName = item.name;
			this.isOpenCommentModal = true;
		},
		handleCloseCommentModal() {
			this.isOpenCommentModal = false;
		}
	}
};
</script>
<style lang="scss">
.vuecal__menu,
.vuecal__cell-events-count {
	background-color: #008b8b;
}
.vuecal__title-bar {
	background-color: #e4f5ef;
}
.vuecal__cell--today,
.vuecal__cell--current {
	background-color: rgba(240, 240, 255, 0.4);
}
.vuecal:not(.vuecal--day-view) .vuecal__cell--selected {
	background-color: rgba(235, 255, 245, 0.4);
}
.vuecal__cell--selected:before {
	border-color: rgba(66, 185, 131, 0.5);
}
/* Cells and buttons get highlighted when an event is dragged over it. */
.vuecal__cell--highlighted:not(.vuecal__cell--has-splits),
.vuecal__cell-split--highlighted {
	background-color: rgba(195, 255, 225, 0.5);
}
.vuecal__arrow.vuecal__arrow--highlighted,
.vuecal__view-btn.vuecal__view-btn--highlighted {
	background-color: rgba(136, 236, 191, 0.25);
}

.vuecal--month-view .vuecal__cell {
	height: 80px;
}

.vuecal--month-view .vuecal__cell-content {
	justify-content: flex-start;
	height: 100%;
	align-items: flex-end;
}

.vuecal--month-view .vuecal__cell-date {
	padding: 4px;
}
.vuecal--month-view .vuecal__no-event {
	display: none;
}
.vuecal__event--dragging {
	background-color: rgba(60, 60, 60, 0.3);
}
.external-event {
	margin-bottom: 0.5em;
	width: 100%;
}
.external-event,
.external-events-drag-and-drop .vuecal__event {
	background-color: rgba(160, 220, 255, 0.5);
	border: 1px solid rgba(0, 100, 150, 0.15);
	padding: 0.2em 0.4em;
	cursor: grab;
}
.vuecal__time-cell-line.hours:before {
	border-color: #42b983;
}
.bc-head-txt-label {
	display: block;
	text-align: left;
	transform: rotate(-90deg);
	margin-top: 19px;
	font-weight: bolder;
	color: white;
	letter-spacing: 2px;
}
.container-hide-show-tasks {
	position: absolute;
	left: 0;
	top: 47%;
	background-color: lightgray;
	height: 60px;
	border-radius: 5px 0 0 5px;
}
.app-body {
	overflow-x: unset;
}

.header-fixed .app-header {
	z-index: 1021;
}

.sidenav-sticky {
	top: 68px;
}

.btn-link.collapse-btn {
	color: #323635;
	text-align: left;
	padding-left: 0;
	padding-right: 0;
	clear: both;
	transition: all 0.4s;
}

.btn-link.collapse-btn:hover,
.btn-link.collapse-btn:active,
.btn-link.collapse-btn:focus {
	padding-left: 8px;
	background-color: #f3f4f5;
	text-decoration: none;
}

.btn-link.collapse-btn i {
	float: right;
	margin: 6px 12px 0 0;
	font-size: 0.7rem;
}
.scroll-area {
	position: relative;
	margin: auto;
	width: 400px;
	height: 300px;
}
/* FOR HIDE THE WEEK DAYS VUE CAL */
#container-calTasks .vuecal__weekdays-headings,
.vuecal__all-day {
	display: none !important;
}

.completed-label {
	border: 1px dotted rgba(0, 0, 0, 0.15);
	line-height: 22px;
	display: inline-table;
}

.completed-edit {
	color: #29ad5e;
	border: 1px dotted rgba(0, 0, 0, 0.15);
	line-height: 22px;
	font-size: 0.925rem;
}

.completed-edit:hover {
	color: #2ecd6f;
}

.completed-col {
	width: 130px;
}

.map {
	font-size: 1.15rem;
	color: #c44611;
	&:hover {
		color: #ee5514;
	}
}

#dropdown-offer-action .dropdown-menu {
	font-size: 12px !important;
}
</style>
