var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "b-col",
        { attrs: { cols: "12", xl: "12" } },
        [
          _c("transition", { attrs: { name: "slide" } }, [
            _c(
              "div",
              [
                _c(
                  "h1",
                  {
                    class: [
                      `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                      { "is-pwa": _vm.$isPwa() },
                    ],
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" + _vm._s(_vm.rendTitle) + "\n\t\t\t\t"
                    ),
                  ]
                ),
                _c(
                  "b-card",
                  { attrs: { "no-body": "" } },
                  [
                    _vm.showBtnNewTask
                      ? _c(
                          "b-card-header",
                          [
                            _c(
                              "b-row",
                              [
                                _c("b-col", [
                                  _c(
                                    "div",
                                    { staticClass: "float-right" },
                                    [
                                      !_vm.isMyWorkSpace && !_vm.isOffer
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "btn btn-primary btn-md float-right",
                                              attrs: {
                                                block: !_vm.$screen.md,
                                                variant: "success",
                                                size: "md",
                                              },
                                              on: { click: _vm.newTask },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    this.FormMSG(4, "New Task")
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.isMyWorkSpace && _vm.isOffer
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "btn btn-primary btn-md float-right",
                                              attrs: {
                                                block: !_vm.$screen.md,
                                                variant: "success",
                                                size: "md",
                                              },
                                              on: { click: _vm.newOffer },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      157,
                                                      "New Offer"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("b-card-body", [
                      _c(
                        "div",
                        { staticStyle: { position: "relative" } },
                        [
                          _c("custom-loading-planning", {
                            attrs: {
                              loading: _vm.screenLoading,
                              label: _vm.FormMSG(
                                105,
                                "Preparing the task list"
                              ),
                            },
                          }),
                          _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _vm.$screen.width >= 992
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "my-1",
                                      attrs: { md: "4", lg: "4" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          staticClass: "mb-0",
                                          attrs: {
                                            label: _vm.FormMSG(2, "Filter"),
                                            "label-cols": "3",
                                            "label-align-sm": "left",
                                            "label-size": "sm",
                                            "label-for": "filterInput",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            { attrs: { size: "sm" } },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  type: "search",
                                                  id: "filterInput",
                                                  placeholder: this.FormMSG(
                                                    3,
                                                    "Type to Search"
                                                  ),
                                                },
                                                model: {
                                                  value: _vm.filter,
                                                  callback: function ($$v) {
                                                    _vm.filter = $$v
                                                  },
                                                  expression: "filter",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        disabled: !_vm.filter,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.filter = ""
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-times",
                                                        attrs: {
                                                          "aria-hidden": "true",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-col",
                                { attrs: { sm: "12", md: "12", lg: "8" } },
                                [
                                  _c(
                                    "fieldset",
                                    {
                                      staticClass: "scheduler-border my-0 py-0",
                                    },
                                    [
                                      _c(
                                        "legend",
                                        { staticClass: "scheduler-border" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(20, "Advanced search")
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("filter-planning", {
                                        attrs: {
                                          "field-filter": "task",
                                          "filter-for-task": true,
                                          "to-me": _vm.isMyWorkSpace,
                                          "is-not-planning": !_vm.isMyWorkSpace,
                                          "only-parent": _vm.chckOnlyParent,
                                          "init-active-view": "day",
                                        },
                                        on: {
                                          "filters:selected":
                                            _vm.handleFiltersSelected,
                                          "new-active-view":
                                            _vm.handleNewActiveView,
                                          "today-event": function ($event) {
                                            _vm.$refs.calTasks.switchView(
                                              "day",
                                              new Date()
                                            )
                                          },
                                          "specific-date:selected":
                                            _vm.handleSpecificDateSelected,
                                          "only-parent:change":
                                            _vm.handleOnlyParentChange,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.projectFormatEmpty &&
                          !_vm.isMyWorkSpace &&
                          _vm.isOffer
                            ? _c(
                                "div",
                                {
                                  staticClass: "alert alert-warning",
                                  attrs: { role: "alert" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-items-center",
                                      staticStyle: {
                                        color: "rgba(0, 0, 0, 0.6)",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "pr-3",
                                          staticStyle: {
                                            "font-size": "1.35rem",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa fa-exclamation-triangle",
                                            attrs: { "aria-hidden": "true" },
                                          }),
                                        ]
                                      ),
                                      _c("div", { staticClass: "mt-0" }, [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                21,
                                                "Before adding a new offer, you need to set up in your project the number format for offer, order and invoice."
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "b-row",
                            { attrs: { id: "container-calTasks" } },
                            [
                              _c(
                                "b-col",
                                [
                                  _c("vue-cal", {
                                    ref: "calTasks",
                                    attrs: {
                                      locale: _vm.lang,
                                      "hide-body": true,
                                      "hide-weekends": true,
                                      "selected-date": _vm.selectedDate,
                                      "hide-view-selector": "",
                                      "hide-week-days": [1, 2, 3, 4, 5, 6, 7],
                                      "active-view": _vm.activeView,
                                    },
                                    on: {
                                      "update:activeView": function ($event) {
                                        _vm.activeView = $event
                                      },
                                      "update:active-view": function ($event) {
                                        _vm.activeView = $event
                                      },
                                      "view-change": _vm.handleViewChange,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-overlay",
                                    {
                                      attrs: {
                                        show: _vm.isBusyTable,
                                        rounded: "sm",
                                      },
                                      on: {
                                        "update:show": function ($event) {
                                          _vm.isBusyTable = $event
                                        },
                                      },
                                    },
                                    [
                                      _vm.$screen.width < 992
                                        ? _c("CardListBuilder", {
                                            attrs: {
                                              items: _vm.tasks,
                                              fields: _vm.taskMobileFields,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "actions",
                                                  fn: function (data) {
                                                    return [
                                                      !_vm.isOffer
                                                        ? _c("div", [
                                                            data.item
                                                              .percentCompleted !==
                                                            100
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex flex-row",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-center completed-label pull-right",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-input",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                height:
                                                                                  "22px",
                                                                                width:
                                                                                  "50px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                type: "number",
                                                                                step: "1",
                                                                                min: "0",
                                                                                max: "100",
                                                                              },
                                                                            on: {
                                                                              keyup:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    !$event.type.indexOf(
                                                                                      "key"
                                                                                    ) &&
                                                                                    _vm._k(
                                                                                      $event.keyCode,
                                                                                      "enter",
                                                                                      13,
                                                                                      $event.key,
                                                                                      "Enter"
                                                                                    )
                                                                                  )
                                                                                    return null
                                                                                  return _vm.savePercent(
                                                                                    data
                                                                                  )
                                                                                },
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  data
                                                                                    .item
                                                                                    .percentCompleted,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      data.item,
                                                                                      "percentCompleted",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "data.item.percentCompleted",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "px-1 completed-edit",
                                                                        attrs: {
                                                                          id: "completed-percent",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.savePercent(
                                                                                data
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "fa fa-check",
                                                                            attrs:
                                                                              {
                                                                                "aria-hidden":
                                                                                  "true",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          show: "",
                                                                          target:
                                                                            "completed-percent",
                                                                          placement:
                                                                            "bottom",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.FormMSG(
                                                                              67,
                                                                              "Save"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _c("div", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      data.item
                                                                        .percentCompleted
                                                                    ) + " %"
                                                                  ),
                                                                ]),
                                                          ])
                                                        : _vm._e(),
                                                      (data.item.addressId >
                                                        0 ||
                                                        data.item.locationId >
                                                          0) &&
                                                      !_vm.isOffer
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  attrs: {
                                                                    variant:
                                                                      "warning",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleClickLocalisation(
                                                                          data.item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fa fa-map-marker",
                                                                      attrs: {
                                                                        "aria-hidden":
                                                                          "true",
                                                                      },
                                                                    }),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.isMyWorkSpace &&
                                                      !_vm.isOffer
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-inline-flex",
                                                            },
                                                            [
                                                              data.item
                                                                .start ===
                                                              "0001-01-01T00:00:00Z"
                                                                ? _c(
                                                                    "b-button",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "line-height":
                                                                            "16px",
                                                                        },
                                                                      attrs: {
                                                                        size: "sm",
                                                                        variant:
                                                                          "outline-warning",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.play(
                                                                              data
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fa fa-play",
                                                                        attrs: {
                                                                          "aria-hidden":
                                                                            "true",
                                                                        },
                                                                      }),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              data.item.end ===
                                                              "0001-01-01T00:00:00Z"
                                                                ? _c(
                                                                    "b-button",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "line-height":
                                                                            "16px",
                                                                        },
                                                                      attrs: {
                                                                        size: "sm",
                                                                        variant:
                                                                          "outline-danger",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.stop(
                                                                              data
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fa fa-stop",
                                                                        attrs: {
                                                                          "aria-hidden":
                                                                            "true",
                                                                        },
                                                                      }),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      !_vm.isOffer
                                                        ? _c(
                                                            "b-button",
                                                            {
                                                              staticStyle: {
                                                                "line-height":
                                                                  "16px",
                                                              },
                                                              attrs: {
                                                                size: "sm",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.doComment(
                                                                      data.item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("b-icon", {
                                                                attrs: {
                                                                  icon: "chat-left-text",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            variant: "primary",
                                                            size: "sm",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editTask(
                                                                data.item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm.isMyWorkSpace
                                                            ? _c("span", [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "icon-eye",
                                                                }),
                                                              ])
                                                            : _c("span", [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "icon-pencil",
                                                                }),
                                                              ]),
                                                        ]
                                                      ),
                                                      !_vm.isMyWorkSpace
                                                        ? _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                variant:
                                                                  "danger",
                                                                size: "sm",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteTask(
                                                                      data.item
                                                                        .taskPlanningId,
                                                                      data.item
                                                                        .id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("span", [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "icon-trash",
                                                                }),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              779046134
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm.$screen.width >= 992
                                        ? _c("b-table", {
                                            ref: "taskTable",
                                            staticStyle: {
                                              "text-align": "left",
                                            },
                                            attrs: {
                                              "selected-variant":
                                                _vm.selectedColor,
                                              hover: _vm.hover,
                                              selectable: "",
                                              "select-mode": _vm.selectMode,
                                              responsive: "sm",
                                              "sticky-header": "700px",
                                              items: _vm.tasks,
                                              fields: _vm.taskFields,
                                              "current-page": _vm.currentPage,
                                              filter: _vm.filter,
                                              "per-page": _vm.perPage,
                                              bordered: "",
                                              small: "",
                                              "head-variant": _vm.hv,
                                              "empty-text": !_vm.isOffer
                                                ? _vm.FormMSG(
                                                    55,
                                                    "No task found"
                                                  )
                                                : _vm.FormMSG(
                                                    68,
                                                    "No offer found"
                                                  ),
                                              "show-empty": "",
                                              "tbody-tr-class": "p-0",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "cell(location)",
                                                  fn: function (data) {
                                                    return [
                                                      data.item.addressId > 0 ||
                                                      data.item.locationId > 0
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-center map",
                                                                  attrs: {
                                                                    id: `tooltip-location-${data.item.taskPlanningId}`,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleClickLocalisation(
                                                                          data.item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa fa-map-marker",
                                                                    attrs: {
                                                                      "aria-hidden":
                                                                        "true",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                              _c(
                                                                "b-tooltip",
                                                                {
                                                                  attrs: {
                                                                    target: `tooltip-location-${data.item.taskPlanningId}`,
                                                                    placement:
                                                                      "righttop",
                                                                  },
                                                                },
                                                                [
                                                                  data.item
                                                                    .locationId ===
                                                                  0
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.getAddressFormater(
                                                                                  data
                                                                                    .item
                                                                                    .client
                                                                                    .address
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.getAddressFormater(
                                                                                  data
                                                                                    .item
                                                                                    .location
                                                                                    .setAddress
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(completed)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-center",
                                                          staticStyle: {
                                                            width: "125px",
                                                          },
                                                        },
                                                        [
                                                          data.item
                                                            .percentCompleted !==
                                                          100
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex flex-row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-sm-8 text-center completed-label",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          ref: `completed-label-${data.item.taskPlanningId}`,
                                                                          staticStyle:
                                                                            {
                                                                              display:
                                                                                "block",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                data
                                                                                  .item
                                                                                  .percentCompleted
                                                                              ) +
                                                                              " %\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          ref: `completed-div-input-${data.item.taskPlanningId}`,
                                                                          staticStyle:
                                                                            {
                                                                              display:
                                                                                "none",
                                                                            },
                                                                          on: {
                                                                            keydown:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  !$event.type.indexOf(
                                                                                    "key"
                                                                                  ) &&
                                                                                  _vm._k(
                                                                                    $event.keyCode,
                                                                                    "esc",
                                                                                    27,
                                                                                    $event.key,
                                                                                    [
                                                                                      "Esc",
                                                                                      "Escape",
                                                                                    ]
                                                                                  )
                                                                                )
                                                                                  return null
                                                                                return _vm.cancelSave(
                                                                                  data
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-form-input",
                                                                            {
                                                                              ref: `completed-input-${data.item.taskPlanningId}`,
                                                                              staticStyle:
                                                                                {
                                                                                  height:
                                                                                    "22px",
                                                                                  width:
                                                                                    "80px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  id: `tooltip-input-${data.item.taskPlanningId}`,
                                                                                  type: "number",
                                                                                  step: "1",
                                                                                  min: "0",
                                                                                  max: "100",
                                                                                },
                                                                              on: {
                                                                                keyup:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    if (
                                                                                      !$event.type.indexOf(
                                                                                        "key"
                                                                                      ) &&
                                                                                      _vm._k(
                                                                                        $event.keyCode,
                                                                                        "enter",
                                                                                        13,
                                                                                        $event.key,
                                                                                        "Enter"
                                                                                      )
                                                                                    )
                                                                                      return null
                                                                                    return _vm.savePercent(
                                                                                      data
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    data
                                                                                      .item
                                                                                      .percentCompleted,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        data.item,
                                                                                        "percentCompleted",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "data.item.percentCompleted",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "b-tooltip",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `tooltip-input-${data.item.taskPlanningId}`,
                                                                              placement:
                                                                                "left",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  60,
                                                                                  "Type `ESC` to discard the change"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      ref: `completed-btn-edit-${data.item.taskPlanningId}`,
                                                                      staticClass:
                                                                        "col-sm-4 px-1 completed-edit",
                                                                      staticStyle:
                                                                        {
                                                                          display:
                                                                            "block",
                                                                        },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.editPercent(
                                                                              data
                                                                                .item
                                                                                .taskPlanningId,
                                                                              data
                                                                                .item
                                                                                .percentCompleted
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fa fa-pencil",
                                                                        attrs: {
                                                                          "aria-hidden":
                                                                            "true",
                                                                        },
                                                                      }),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      ref: `completed-btn-save-${data.item.taskPlanningId}`,
                                                                      staticClass:
                                                                        "col-sm-4 px-1 completed-edit",
                                                                      staticStyle:
                                                                        {
                                                                          display:
                                                                            "none",
                                                                        },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.savePercent(
                                                                              data
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fa fa-check",
                                                                        attrs: {
                                                                          "aria-hidden":
                                                                            "true",
                                                                        },
                                                                      }),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _c("div", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    data.item
                                                                      .percentCompleted
                                                                  ) + " %"
                                                                ),
                                                              ]),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(play)",
                                                  fn: function (data) {
                                                    return [
                                                      data.item.start ===
                                                      "0001-01-01T00:00:00Z"
                                                        ? _c(
                                                            "b-button",
                                                            {
                                                              staticStyle: {
                                                                "line-height":
                                                                  "16px",
                                                              },
                                                              attrs: {
                                                                size: "sm",
                                                                variant:
                                                                  "outline-warning",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.play(
                                                                      data
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-play",
                                                                attrs: {
                                                                  "aria-hidden":
                                                                    "true",
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(stop)",
                                                  fn: function (data) {
                                                    return [
                                                      data.item.end ===
                                                      "0001-01-01T00:00:00Z"
                                                        ? _c(
                                                            "b-button",
                                                            {
                                                              staticStyle: {
                                                                "line-height":
                                                                  "16px",
                                                              },
                                                              attrs: {
                                                                size: "sm",
                                                                variant:
                                                                  "outline-danger",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.stop(
                                                                      data
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-stop",
                                                                attrs: {
                                                                  "aria-hidden":
                                                                    "true",
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(edit)",
                                                  fn: function (data) {
                                                    return [
                                                      _vm.isOffer
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "mx-2 btn bg-transparent border-0",
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.editOffer(
                                                                          data.item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    _vm.getLucideIcon(
                                                                      _vm.ICONS
                                                                        .EDIT
                                                                        .name
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      attrs: {
                                                                        color:
                                                                          _vm
                                                                            .ICONS
                                                                            .EDIT
                                                                            .color,
                                                                        size: 20,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-transparent border-0",
                                                                  staticStyle: {
                                                                    "line-height":
                                                                      "16px",
                                                                  },
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    size: "sm",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.editTask(
                                                                          data.item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm.isMyWorkSpace
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _c(
                                                                            _vm.getLucideIcon(
                                                                              _vm
                                                                                .ICONS
                                                                                .EYE
                                                                                .name
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "#225CBD",
                                                                                  size: 20,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          _c(
                                                                            _vm.getLucideIcon(
                                                                              _vm
                                                                                .ICONS
                                                                                .EDIT
                                                                                .name
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    _vm
                                                                                      .ICONS
                                                                                      .EDIT
                                                                                      .color,
                                                                                  size: 20,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(totalBudget)",
                                                  fn: function (data) {
                                                    return [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.displayCurrency(
                                                              data.item
                                                                .totalBudget
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(totalBudgetTtc)",
                                                  fn: function (data) {
                                                    return [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.displayCurrency(
                                                              data.item
                                                                .totalBudgetTtc
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(contextMenu)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "action-offer-context-menu",
                                                        {
                                                          attrs: {
                                                            record: data.item,
                                                            records: _vm.tasks,
                                                          },
                                                          on: {
                                                            "action-menu-share-offer:sended":
                                                              _vm.shareOffer,
                                                            "offer-action:refresh":
                                                              _vm.reloadData,
                                                          },
                                                        }
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(chat)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "btn bg-transparent border-0",
                                                          staticStyle: {
                                                            "line-height":
                                                              "16px",
                                                            color: "#225cbd",
                                                          },
                                                          attrs: { size: "sm" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.doComment(
                                                                data.item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("b-icon", {
                                                            attrs: {
                                                              icon: "chat-left-text",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(delete)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex justify-content-center",
                                                        },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "btn bg-transparent border-0",
                                                              staticStyle: {
                                                                "line-height":
                                                                  "16px",
                                                              },
                                                              attrs: {
                                                                variant:
                                                                  "danger",
                                                                size: "sm",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteTask(
                                                                      data.item
                                                                        .taskPlanningId,
                                                                      data.item
                                                                        .id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS
                                                                    .TRASH.name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .TRASH
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2675563287
                                            ),
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c("comment-modal", {
                  attrs: {
                    "key-value": {
                      xid: _vm.currentXid,
                      imageParentId: _vm.currentRootId,
                    },
                    title: _vm.currentTaskName,
                  },
                  on: { "comment-modal:hidden": _vm.handleCloseCommentModal },
                  model: {
                    value: _vm.isOpenCommentModal,
                    callback: function ($$v) {
                      _vm.isOpenCommentModal = $$v
                    },
                    expression: "isOpenCommentModal",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }