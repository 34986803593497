<template>
	<div class="d-flex justify-content-center" style="min-width: 60px">
		<b-dropdown
			v-if="item"
			id="dropdown-offer-action"
			size="sm"
			:text="FormMSG(1, 'Menus')"
			variant="light"
			split
			dropleft
			:class="lengthRecords <= 8 ? 'position-absolute' : ''"
		>
			<share-information
				:key="item.id"
				:btn-label="FormMSG(2, 'Share Offer')"
				:title-modal="FormMSG(3, 'Share the offer to :')"
				:offer="item"
				tag="b-dropdown-item"
				:name-modal="`modal-share-offer${item.id}`"
				icon="fa fa-share-alt"
				variant-btn="info"
				class="mr-2"
				@share-information:sended="shareOffer"
			/>
			<b-dropdown-item @click="downloadOfferInPdf(item)">
				<b-icon style="color: red" icon="file-earmark-pdf-fill" />
				{{ FormMSG(10, 'Download in PDF') }}
			</b-dropdown-item>
			<b-dropdown-item @click="downloadOfferInDoc(item)">
				<b-icon style="color: #317ac1" icon="file-earmark-word-fill" />
				{{ FormMSG(11, 'Download in Word') }}
			</b-dropdown-item>
			<b-dropdown-item @click="downloadOfferInXls(item)">
				<b-icon style="color: green" icon="file-excel-fill" />
				{{ FormMSG(13, 'Download in Excel') }}
			</b-dropdown-item>
			<b-dropdown-item :disabled="item.sendStatus === 5" @click="handleClickAccepted(item)">
				<b-icon icon="check-circle" style="color: blue" />
				{{ FormMSG(5, 'Accept offer') }}
			</b-dropdown-item>
			<b-dropdown-item :disabled="!(item.sendStatus === 5 && item.isTaskCreated === false)" @click="handleCreateTask(item)">
				<b-icon icon="list-task" style="color: #a64b2a" />
				{{ FormMSG(6, 'Create tasks') }}
			</b-dropdown-item>
			<b-dropdown-item :disabled="item.sendStatus === 0" @click="handleRejectOffer(item)">
				<b-icon icon="x-circle" style="color: red" />
				{{ FormMSG(7, 'Reject offer') }}
			</b-dropdown-item>
			<b-dropdown-item @click="handleCopyOffer(item)">
				<b-icon icon="subtract" style="color: green" />
				{{ FormMSG(8, 'Copy offer') }}
			</b-dropdown-item>
			<b-dropdown-item @click="handleVariantOffer(item)">
				<b-icon icon="stickies" style="color: green" />
				{{ FormMSG(9, 'Create offer variant') }}
			</b-dropdown-item>
		</b-dropdown>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import OffersMixin from '@/components/Offers/offers.mixin';
import { isNil } from '~utils';
import ShareInformation from '@/components/InfoSheet/ShareInformation';
import { isTaskAlreadyCreated } from '@/cruds/task.crud';

export default {
	name: 'ActionOfferContextMenu',
	components: {
		ShareInformation
	},
	mixins: [languageMessages, OffersMixin],
	props: {
		record: {
			type: Object,
			required: true,
			default: () => {}
		},
		records: { type: Array, default: [] }
	},
	data() {
		return {
			item: null
		};
	},
	computed: {
		lengthRecords() {
			return this.records.length;
		}
	},
	watch: {
		record: {
			async handler(n) {
				if (!isNil(n)) {
					await this.initData();
				}
			}
		},
		deep: true,
		immediate: true
	},
	mounted() {
		this.$nextTick(async () => {
			await this.initData();
		});
	},
	methods: {
		async initData() {
			this.item = this.record;
			this.$set(this.item, 'isTaskCreated', await this.isTaskCreated(this.item));
			// console.log({id: this.item.offerNumber, isTaskCreated: this.item.isTaskCreated, status: this.item.sendStatus});
		},
		async isTaskCreated(item) {
			return await isTaskAlreadyCreated(item.id);
		},
		shareOffer(payload) {
			this.$emit('action-menu-share-offer:sended', payload);
		}
	}
};
</script>
