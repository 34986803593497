var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: _vm.componentRef,
      staticClass: "google-map-loader component--wrapper",
    },
    [
      _vm.isMapDetected
        ? [_vm._t("default", null, { google: _vm.google, map: _vm.map })]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }